import React from 'react'
import styled from 'styled-components'
import {graphql} from 'gatsby'

import Layout from '../layout'
import Banner from '../components/banner'
import Img from '../components/image'

import {colors, MOBILE_BREAKPOINT} from '../helpers/constants'

const Incentive = styled.div`
  display: flex;
  align-items: center;
  margin-top: 55px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 30px;
  }
`
const Image = styled(Img)`
  width: 380px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 145px;
  }
`
const Tagline = styled.h1`
  font-size: 4rem;
  margin: 0;
  text-align: center;
  margin-left: -20px;
  em {
    font-size: 5.2rem;
    font-style: normal;
  }
  span {
    font-size: 3.5rem;
  }
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    text-align: left;
    font-size: 1.25rem;
    margin-top: 30px;
    margin-left: -10px;
    em {
      font-size: 1.6rem;
    }
    span {
      font-size: 1.25rem;
    }
  }
`
const Subscribe = styled.a`
  background: ${colors.primary};
  color: ${colors.white};
  display: inline-block;
  margin: 40px 0 0;
  border-radius: 30px;
  padding: 15px 40px;
  font-weight: 600;
  font-size: 1.5rem;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    font-size: 0.85rem;
    padding: 6px 14px;
    margin: 10px 25% 5px;
  }
`
const Line = styled.div`
  width: 660px;
  height: 12px;
  background: ${colors.primary};
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    width: 100%;
    height: 5px;
  }
`
const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 60px -20px 10px 0;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin-top: 30px;
  }
`
const Article = styled.article`
  width: 320px;
  margin-right: 20px;
`
const Title = styled.h2`
  text-align: center;
  font-size: 1.25rem;
  margin: 60px 0 20px;
  @media (max-width: ${MOBILE_BREAKPOINT}px) {
    margin: 20px 0;
  }
`
const Text = styled.div`
  margin: 1em 0;
  line-height: 1.4rem;
  font-size: 0.875rem;
`

const AdherezPage = ({data}) => (
  <Layout title="Adhérez">
    <Banner />
    <Incentive>
      <Image src={data.file} />
      <Tagline>
        Adhérez au <em>Bikini</em>
        <br />
        <span>et tout vous sera permis</span>
        <br />
        <Subscribe href="https://web.digitick.com/adhesion-bikini-le-bikini-ramonville-non-datee-css5-lebikini-pg101-ri6788359.html">
          Adhérer
        </Subscribe>
      </Tagline>
    </Incentive>
    <Line />
    <Content>
      {data.allBikiniSection.edges.map(({node: {slug, title, content}}) => (
        <Article key={slug}>
          <Title>{title}</Title>
          <Text dangerouslySetInnerHTML={{__html: content}} />
        </Article>
      ))}
    </Content>
  </Layout>
)

export default AdherezPage

export const query = graphql`
  query {
    file(name: {eq: "stalin"}) {
      childImageSharp {
        fluid(maxHeight: 482) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    allBikiniSection(filter: {page: {eq: "adhesion"}}, sort: {fields: [order], order: ASC}) {
      edges {
        node {
          slug
          title
          content
        }
      }
    }
  }
`
